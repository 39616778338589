import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './store'; // 数据仓库
import { PersistGate } from 'redux-persist/integration/react' // 持久化存储
import './index.css';
import App from './App';
import './assets/iconfont/iconfont.css';
import './styles/theme.css';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root')

createRoot(rootElement).render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

serviceWorker.unregister();
