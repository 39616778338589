import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';

const NotFound = (props) => {
    const navigate = useNavigate();
    const goback = () => {
        navigate(-1);
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle="对不起，你访问的页面不存在！"
            extra={<Button type="primary" onClick={goback}>返回</Button>}
        />
    )
}
export default NotFound;