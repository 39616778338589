import React, { memo, Suspense } from 'react';
// import language from './lang';
import { Routes } from './router/renderRoutes';
import  { connect } from 'react-redux';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider, App } from 'antd';
import 'dayjs/locale/zh-cn';
import { ThemeProvider } from 'antd-style';


interface NewToken {
    full: string;
    colorMain: string;
    minUnit: number;
}

declare module 'antd-style' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface CustomToken extends NewToken {}
}
  const mapPropsToState = (state: any) => {
	return state.setting
}
const MyApp = memo(_ => {
	// 设置国际化
    // const getLocalMessage = () => {
	// 	let msg;
    //     if (props.language === 'zh-CN' || props.language === 'zh') {
	// 		msg = language.zh
    //     } else {
    //         msg = language.en
    //     }
    //     return {...msg}
	// }

    return (
        <div className="App">
            <ThemeProvider<NewToken>
                theme={{
                    token: {  colorPrimary: '#257FFF' },
                }}
                customToken={() => ({
                    full: '100%',
                    colorMain: '#f0f2f5',
                    minUnit: 0
                })}>
                <ConfigProvider 
                    key="intl" locale={zhCN}>
                        <App>
                            <Suspense fallback={
                                <div className="app-loading">
                                    <div className="page-loading-warp">
                                        <div className="ant-spin ant-spin-lg ant-spin-spinning">
                                            <span className="ant-spin-dot ant-spin-dot-spin"><i className="ant-spin-dot-item"></i><i className="ant-spin-dot-item"></i><i className="ant-spin-dot-item"></i><i className="ant-spin-dot-item"></i></span>
                                        </div>
                                    </div>
                                </div>}>
                                <Routes />
                            </Suspense>
                        </App>
                </ConfigProvider>
            </ThemeProvider>
        </div>
    );
})
export default connect(mapPropsToState)(MyApp);
