import { lazy } from 'react';

import NotFound from '@/views/NotFound/NotFound';
import Auth from '@/views/login/auth';
import WeAuth from '@/views/login/weAuth';

const Login = lazy(() => import('@/views/login/login'));

const constantRoutes = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/auth',
        component: Auth
    },
    {
        path: '/weAuth',
        component: WeAuth
    },
    {
        path: '*',
        component: NotFound
    }
]
export default constantRoutes;