import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCode } from '@/service/user';
// import { refreshToken } from '@/service/user';
// import { PROJECT, REFRESHTOKEN } from '@/store/action-types';
// import { setCookie, getCookie } from '@/utils/echo';

// 获取路由指定参数
const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return (false);
}

// 是否是企业微信
const isWechat = () => {
    return /wxwork/i.test(navigator.userAgent)
}
// const handlerRefreshToken = () => {
    // navigate.listen(async( h ) => {
    // 	writeBreadcrumb(h.pathname, h.search)
    // 	const date = Math.floor(Date.now() / 1000);
    // 	if ((getCookie(PROJECT) && getCookie(REFRESHTOKEN) && ((getCookie(REFRESHTOKEN) - date) <= 7200)  && (getCookie(REFRESHTOKEN) - date  > 0))) {
    // 		const data = await refreshToken();
    // 		if (data.code === 200) {
    // 			setCookie(REFRESHTOKEN, '');
    // 			setCookie(PROJECT, data.data.access_token);
    // 		}
    // 	}
    // })
// }

// 授权
const isAuth = async() => {
    const client_id =  window.location.search.split('=')[1] || decodeURIComponent(getQueryVariable('state')) || '';
    const res = await getCode();
    if (res.code === 200) {
        const response = res.data;
        const appid = response.appid
        const new_url = response.oauth2_login_url + '?appid=' + appid + '&redirect_uri=' + window.location.origin + '/weauth&response_type=code&scope=snsapi_base&state=' + window.location.pathname + '_' + client_id +'#wechat_redirect';
        window.location.href = new_url;
    }
}
// 私有化路由
const ProtectedRoute = ({ isPublic, isAuthorized }) => {
  return (isPublic || isAuthorized) ? <Outlet /> : (isWechat() ? isAuth() :  <Navigate to='/login' />)
}

export default ProtectedRoute;