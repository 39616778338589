import { http } from '@/config/axios.config';
import API from '@/config/api.config';
import axios from 'axios';
import qs from 'qs';

// 登录
// export const Login = async(url, data = {}) => { // eslint-disable-line
//     return http.post(url, data);
// };
// 登录
export const login = (data) => {
    return http.post(API.LOGIN, data);
}
// sso授权登录
export const authToken = (data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: process.env.REACT_APP_OAUTH + API.oauthToken,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data)
        }).then(res => {
            resolve(res);
        }).catch(res => {
            if (res.response.status === 500) {
                reject('响应异常！');
            }
        });
    });
}
// 用户信息
export const userInfo = () => {
    return http.get(API.PROFILE)
}

// 菜单
export const menu = () => {
    return http.get(API.MENU);
}
// 获取授权code
export const getCode = () => {
    return http.get(`${API.AUTHLINK}?platform=wecom`);
}
// 根据授权code获取token
export const getWeToken = (param) => {
    return http.post(`${API.AUTHWECOMTOKEN}`, param);
}
// 刷新token
export const refreshToken = () => {
    return http.post(`${API.REFRESHTOKEN}`, {});
}