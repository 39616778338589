import {
    SETTING,
    SELECTEDKEYS,
    ROUTES,
    SETCOLLAPSED,
    DEFAULTOPENKEYS,
    OPENKEYS,
    BREADCRUMB,
    COLUMS,
    UNREADCOUNTS,
} from '../action-types'
import { getStatistics } from '@/service/customer';

const initState = {
    language: 'zh',
    routes: '',
    openKeys: '', // 当前展开的一级菜单
    selectedKeys: ['/dashboard'],
    collapsed: false,
    defaultOpenKeys: '',
    breadcrumb: [],
    columns: {},
    domain: process.env.REACT_APP_IMAGE,
    mooncake_domain: process.env.REACT_APP_MOONCAKE_domain,
    unread_count: {
        schedules: 0,
        totalMail: 0
    }
}
export default function settingReducer(state = initState, action) {
    switch (action.type) {
        case SETTING:
            return Object.assign({}, state, { language: action.payload });
        case SELECTEDKEYS:
            return Object.assign({}, state, { selectedKeys: action.payload });
        case DEFAULTOPENKEYS:
            return Object.assign({}, state, { defaultOpenKeys: action.payload });
        case OPENKEYS:
            return Object.assign({}, state, { openKeys: action.payload });
        case SETCOLLAPSED:
            return Object.assign({}, state, { collapsed: action.payload });
        case ROUTES:
            return Object.assign({}, state, { routes: action.payload });
        case BREADCRUMB:
            return Object.assign({}, state, { breadcrumb: action.payload });
        case COLUMS:
            return Object.assign({}, state, { columns: {...state.columns, ...action.payload } });
        case UNREADCOUNTS:
            return Object.assign({}, state, { unread_count: action.payload });
        default:
            return state;
    }
}
// 获取未读消息数据
export const fetchUnreadCounts = (userId) => async (dispatch) => {
    try {
        const response = await getStatistics(`?recipient_id=${userId}`);
        const { code, data } = response;
        if (code === 0) {
            dispatch({
                type: UNREADCOUNTS,
                payload: { schedules: data.total_schedule, totalMail: data.total_mail },
            });
        }
    } catch (error) {
        console.error(error);
    }
};