import React from 'react';
import { Route,
    Navigate,
    useLocation,
    Outlet,
    Routes as ReactRoutes
} from 'react-router-dom';

import ProtectedRoute from './protectedRoute';
import constantRoutes from './constantRoutes';
import { getCookie } from '@/utils/echo';
import querystring from 'query-string';


// 递归渲染子路由
export const getChildRoutes = (children) => {
    if (!children) return [];
   return children.map(({children: childRoutes, component: SubComponent, path}) => {
        return (SubComponent && path && (
            <Route key="subChild" element={<Outlet />}>
                <Route key={path} element={<SubComponent />} path={path} ></Route>
                {childRoutes ? <Route key={path} element={<Outlet />} path={path} >
                    {getChildRoutes(childRoutes)}
                </Route> : null}
            </Route>))
    })
}
export const renderRoutes = (mainRoutes) => {
    const Routes = () => {
        const location = useLocation();
        const { client_id } = querystring.parse(location.search)
        const isAuthorized = getCookie('access_token');
        const layouts = mainRoutes.map(({ component: Component, children }, index) => {
            return (
                <Route key={index} element={<Component />}>
                    {children.map(({ path, meta, children: subChildren, component: SubComponent }, i) => {
                        return (
                            <Route key={path + i} element={<ProtectedRoute isPublic={meta?.isPublic} isAuthorized={isAuthorized} />}>
                                <Route key={path} element={SubComponent ? <SubComponent /> : (location.pathname === path ? <Navigate to="/404" replace/>  : <Outlet />)} path={path}>
                                    { !SubComponent && getChildRoutes(subChildren) }
                                </Route>
                                { SubComponent && subChildren && <Route key={path} element={<Outlet />} path={path}>
                                    { getChildRoutes(subChildren) }
                                </Route> }
                            </Route>
                        )
                    })}
                </Route>
            )
        });
        return <ReactRoutes>
            {layouts}
            <Route path="/" element={<Navigate to="/login" />} />
            {location.pathname.indexOf('home/inquiryInfo') !== -1 ? <Route path={location.pathname} element={<Navigate to={`/inquiry/estate-inquiry/inquiryInfo/${location.pathname.split('/').pop()}`} replace />} />: null}
            {location.pathname.indexOf('home/editCustomerInfo') !== -1 ? <Route path={`${location.pathname}`} queryParams={{ client_id: client_id }} element={<Navigate to={`/customer/editCustomerInfo/${location.pathname.split('/').pop()}?client_id=${client_id}`} replace />} />: null}
            {constantRoutes.map(({component: ConstantComponent, path}) => {
                return (
                    <Route key={path} element={<ConstantComponent />} path={path} />
                )
            })}
        </ReactRoutes>;
    }
    return Routes;
}
