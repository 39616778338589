export const PROJECT = 'access_token'; // 项目的key

/* Project */
export const APICATEGORY = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    CHANGE: 'CHANGE',
    QUERY: 'QUERY',
}

/* user profile */
export const SETTING = 'SETTING'
export const LOGINOUT = 'LOGINOUT'
export const USERINFO = 'USERINFO'
export const LOGININFO = 'LOGININFO'
export const REFRESHTOKEN = 'REFRESHTOKEN'
/* Menu */
export const SELECTEDKEYS = 'SELECTEDKEYS'
export const ROUTES = 'ROUTES'
export const SETCOLLAPSED = 'SETCOLLAPSED'
export const DEFAULTOPENKEYS = 'DEFAULTOPENKEYS'
export const OPENKEYS = 'OPENKEYS'
export const BREADCRUMB = 'BREADCRUMB'
export const COLUMS = 'COLUMS'
/* UNREADCOUNTS */
export const UNREADCOUNTS = 'UNREADCOUNTS'


