import {
    USERINFO,
    LOGININFO
} from '../action-types'

const initState = {
    userInfo: '',
    loginInfo: {
        password: '',
        email: '',
        remember: false
    }
}
export default function userReducer(state = initState, action) {
    switch (action.type) {
        case USERINFO:
            return Object.assign({}, state, {userInfo: action.payload});
        case LOGININFO: 
            return Object.assign({}, state, {loginInfo: action.payload.remember ? action.payload : {password: '', email: '', remember: false}});
        default:
            return state;
    }
}