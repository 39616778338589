import axios from 'axios'
import NProgress from 'nprogress'
import { PROJECT } from '@/store/action-types';
import { getCookie, setCookie } from '@/utils/echo';
import { message } from 'antd';
import 'nprogress/nprogress.css';
/* token */
NProgress.configure({ showSpinner: false });
const instance = axios.create({
	timeout: 30000,
  })
// axios.defaults.withCredentials = true;
instance.defaults.headers['Content-Type'] = 'application/json';
instance.interceptors.request.use(function (config) {
	// 在发送请求之前做些什么
	if (config.loading) NProgress.start();
	/* 可以进行token校验 */
	let token = getCookie(PROJECT); // cookie拿不到就从本地存储拿
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
		return config;
	}
	return config;
}, function (error) {
	// 对请求错误做些什么
	NProgress.done();
	return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
	// 对响应数据做点什么
    // 更新token
	if (response.headers.Authorization) setCookie(PROJECT, response.headers.Authorization, {expires: 2.5});
	NProgress.done();
	return response;
}, function (error) {
    const { duration = 3 } = error.config.customData || {};
	// 对响应错误做点什么
	NProgress.done()
	if (error.response) {
		switch (error.response.status) {
			case 401:
				// 返回 401 清除token信息并跳转到登录页面
				window.location.href = '/login'
				setCookie(PROJECT, '');
				break;
			default:
				if (error.response.request.responseType === 'blob') {
					// 将blob文件流转换成json
					const reader = new FileReader()
					reader.onload = function (event) {
						const res = reader.result && JSON.parse(reader.result);
						const msg = res && res.message;
						if (msg) message.error(msg);
					}
					reader.readAsText(error.response.data)
				} else {
					message.error(error.response.data.message, [duration]);
				}
				break;
		}
	}else if (error.code === 'ECONNABORTED') {
        // 请求超时处理
        message.error('请求超时，请重试。');
    } else {
        // 其他网络或请求错误处理
        message.error('请求错误，请检查网络后重试。');
    }
	return Promise.reject(error);
});

export const http = {
	/** get 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} params
	 * @param  {请求参数} loading
	 */
	get: async (url, params, loading) => {
		try {
			params = params || {}
			let res = await instance.get(url, {params, loading});
			res = res.data;

			return new Promise((resolve, reject) => {
				if (res.code === 200 || res.code === 0) {
					resolve(res);
				} else {
					reject(res);
				}
			});
		} catch (err) {
			return (err.response.data)
		}
	},
	/** post 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {请求参数} loading
	 */
	post: async (url, data, loading) => {
		try {
			let res = await instance.post(url, data, loading);
			res = res.data
			return new Promise((resolve, reject) => {
				if (res.code === 200 || res.code === 0) {
					resolve(res)
				} else {
					reject(res)
				}
			});
		} catch (err) {
			return (err.response && err.response.data)
		}
	},
	/** put 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {请求参数} loading
	 */
	put: async(url, data = {}, loading, duration = 3) => {
        try {
			let res = await instance.put(url, data, loading, duration);
			res = res.data;
			return new Promise((resolve, reject) => {
				if (res.code === 200 || res.code === 0) {
					resolve(res);
				} else {
					reject(res);
				}
			});
		} catch (err) {
			return (err.response.data)
		}
	},
	/** patch 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} params
	 * @param  {请求参数} loading
	 */
	patch: async (url, params = {}, loading) => {
		try {
			params = params || {}
			let res = await instance.patch(url, params, loading);
			res = res.data;
			return new Promise((resolve, reject) => {
				if (res.code === 200 || res.code === 0) {
					resolve(res);
				} else {
					reject(res);
				}
			});
		} catch (err) {
			return (err.response.data)
		}
	},
	/** delete 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {请求参数} loading
	 */
	delete: async(url, data = {}, loading = false) => {
        try {
			let res = await instance.delete(url, data, loading);
			res = res.data;
			return new Promise((resolve, reject) => {
				if (res.code === 200 || res.code === 0) {
					resolve(res);
				} else {
					reject(res);
				}
			});
		} catch (err) {
			return (err.response.data)
		}
	}
}
export default instance;