import { http } from '@/config/axios.config';
import API from '@/config/api.config';
import axios from '@/config/axios.config';

 /* 注册用户 */
 // 注册用户列表及详情
export const getCustomer = (param) => {
    return http.get(`${API.CUSTOMER}`, param);
}
// 注册用户详情
export const getCustomerDetail = (param) => {
    return http.get(`${API.CUSTOMER}/${param}`);
}
// // 添加
// export const add = (data) => {
//     return http.post(`${API.CUSTOMER}`, data);
// }
// 修改用户扩展信息
export const editCustomer = (data, param) => {
    return http.put(`${API.CUSTOMER}/${data}`, param);
}
// 移交用户
export const transferCustomer = (data, param) => {
    return http.patch(`${API.CUSTOMERINFORGO}/${data}`, param);
}
// 恢复客户
export const withdraw = (data) => {
    return http.patch(`${API.CUSTOMERINFOR}/${data}/restore`, {});
}

// 获取客户信息及详情
export const getCustomerInfo = (param) => {
    return param ? http.get(`${API.CUSTOMERINFOR}/${param}`) : http.get(`${API.CUSTOMERINFOR}`)
}
// 获取客户列表数据
export const getCustomerDatas = (param) => {
    return http.get(`${API.CUSTOMERINFORMATION}`, param)
}
// 导出客户信息及详情
export const exportCustomerInfo = (param) => {
    return new Promise((resolve, reject) => {
        axios({
            responseType: 'blob',
            method: 'get',
            url: API.CUSTOMERINFOR,
            params: param
        }).then(res => {
            resolve(res);
        }).catch(res => {
            // 将blob文件流转换成json
            if (res.response.status === 500) {
                reject('响应异常！');
            } else {
                const reader = new FileReader()
                reader.onload = function (event) {
                    const msg = JSON.parse(reader.result)
                    reject(msg);
                }
                reader.readAsText(res.response.data)
            }
        });
    });
}

// 添加客户信息
export const addCustomerInfo = (data) => {
    return http.post(`${API.CUSTOMERINFOR}`, data)
}
  
// 编辑客户信息
export const editCustomerInfo = (data, param) => {
    return http.put(`${API.CUSTOMERINFORMATION}/${data}`, param);
}
  
// 删除客户信息
export const delCustomerInfo = (data, param) => {
    return http.delete(`${API.CUSTOMERINFOR}/${data}${param}`)
}
// 批量删除客户
export const batchDelCustomer = (data) => {
    return http.post(`${API.CUSTOMERINFOR}/batch/destroy`, data)
}

// 客户信息下拉列表
export const customerOptions = (data = 'all') => {
    return http.get(`${API.CUSTOMEROPTIONSGO}?scenes=${data}`)
}

// 获取对应记录
export const trackList = (param) => {
    return http.get(`${API.CUSTOMERTRACKING}`, param)
}
// 获取操作记录
export const operationList = (data) => {
    return http.get(`${API.CUSTOMERINFOR}/${data}/audit`)
}

// 获取对应记录下拉选项
export const trackOptions = (param) => {
    return http.get(`${API.TRACKOPTIONS}?scenes=${param}`, param)
}

// 添加对应记录
export const addTrack = (param, data='') => {
    return http.post(`${API.CUSTOMERTRACKINGCRM}${data}`, param)
}
// 放回公海
export const releaseTrack = (param) => {
    return http.put(`${API.RELEASECUSTOMER}/${param}`)
}
// 编辑对应记录
export const editTrack = (param, data) => {
    return http.put(`${API.CUSTOMERTRACKINGCRM}/${param}`, data)
}
// 删除对应记录
export const delTrack = (params) => {
    return http.delete(`${API.CUSTOMERTRACKING}/${params}`)
}

// 导出对应记录
export const exportTrackList = (param) => {
    return new Promise((resolve, reject) => {
        axios({
            responseType: 'blob',
            method: 'get',
            url: API.CUSTOMERTRACKINGGO,
            params: param
        }).then(res => {
            resolve(res);
        }).catch(res => {
            // 将blob文件流转换成json
            if (res.response.status === 500) {
                reject();
            } else {
                const reader = new FileReader()
                reader.onload = function (event) {
                    const msg = JSON.parse(reader.result)
                    reject(msg);
                }
                reader.readAsText(res.response.data)
            }
        });
    });
}

// 创建日程
export const addSchedule = (data) => {
    return http.post(`${API.SCHEDULE}`, data)
}

// 日程列表
export const getSchedule = (param) => {
    return http.get(`${API.SCHEDULE}`, param)
}

// 日程，消息提醒数量
export const getStatistics = (param) => {
    return http.get(`${API.STATISTICS}${param}`)
}

// 消息提醒列表数据
export const getMessageList = (param) => {
    return http.get(`${API.MESSAGELIST}${param}`)
}

// 提醒列表数据变已读
export const putMessageInf = (data) => {
    return http.put(`${API.MESSAGEINF}/${data}`)
}

// 提醒列表数据全部变已读
export const allReadMessage = (data) => {
    return http.put(`${API.MESSAGEINFALL}/${data}`)
}

// 删除提醒列表数据
export const delMessageInf = (data) => {
    return http.delete(`${API.MESSAGEINF}/${data}`)
}

// 删除日程
export const delSchedule = (param) => {
    return http.delete(`${API.SCHEDULE}/${param}`)
}

// 编辑日程
export const editSchedule = (param, data) => {
    return http.put(`${API.SCHEDULE}/${param}`,data)
}

// 获取客户注销审核列表
export const getCustomerUnregister = (data) => {
    return http.get(`${API.CUSTOMERUNREGISTER}`, data, true)
}

// 获取客户注销审核状态下拉列表
export const getCustomerUnregisterOptions = () => {
    return http.get(`${API.CUSTOMERUNREGISTER}/options`)
}

// 获取注销待审核数据
export const getCustomerUnregisterCount = () => {
    return http.get(`${API.CUSTOMERUNREGISTER}/count`)
}

// 客户注销审核
export const customerUnregisterAudit = (data, param) => {
    return http.put(`${API.CUSTOMERUNREGISTER}/${data}`, param);
}

// 获取黑名单列表
export const getblackList = (param) => {
    return http.get(`${API.BLACKLIST}`, param)
}

// 编辑黑名单信息
export const editBlackInfo = (data, param) => {
    return http.put(`${API.BLACKLIST}/${data}`, param);
}
  
// 删除黑名单
export const delBlackInfo = (data) => {
    return http.delete(`${API.BLACKLIST}/${data}`)
}

// 添加黑名单
export const addBlackInfo = (data) => {
    return http.post(`${API.BLACKLIST}`, data);
}

// 编辑客户诉求
export const editCustomerRequirement = (param, data) => {
    return http.put(`${API.CUSTOMERREQUIREMENT}/${param}`, data);
}

// 获取附件列表
export const getRequirementList = (param) => {
    return http.get(`${API.REQUIREMENTANNEX}`, param)
}

// 修改附件名称
export const editRequirement = (param, data) => {
    return http.put(`${API.REQUIREMENTANNEX}/${param}`,data)
}

// 删除附件
export const delRequirement = (param) => {
    return http.delete(`${API.REQUIREMENTANNEX}/${param}`)
}

// slack授权链接
export const slackInfo = () => {
    return http.get(API.SLACKINFO)
}

// 领取客户
export const receiveCustomer = (param) => {
    return http.put(`${API.CUSTOMERASSIGN}/${param}`, {}, { customData: {duration: 6} })
}