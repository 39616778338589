import React, { useEffect, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { AuthProvider } from "oidc-react";
import querystring from 'query-string';
import { authToken } from '@/service/user';
import { setCookie } from '@/utils/echo';
import { PROJECT } from '@/store/action-types';
import { App } from 'antd';
import { connect } from 'react-redux';
// import { userInfo } from '@/service/user';
import jwt_decode from 'jwt-decode';

const mapPropsToState = (state) => {
    return state.setting
}
const Auth = (props) => {
    const { message } = App.useApp();
    const navigate = useNavigate();
    const location = useLocation();
	useEffect(() => {
        async function init() {
            const { code, state } = querystring.parse(location.search);
            if (code) {
                const obj = {
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: window.location.origin + '/auth',
                    client_id: '174711412445219340@crm',
                    code_verifier: state
                }
                const data = await authToken(obj);
                if (data.status === 200) {
                    message.success('登录成功！', 1);
                    setCookie(PROJECT, data.data.access_token, { expires: data.expires_in/86400 - 1 });
                    const use_info = jwt_decode(data.data.id_token);
                    props.dispatch({
                        type: 'USERINFO',
                        payload: use_info.name
                    })
                    props.dispatch({
                        type: 'OPENKEYS',
                        payload: '/dashboard'
                    })
                    props.dispatch({
                        type: 'SELECTEDKEYS',
                        payload: ['/dashboard']
                    })
                    navigate('/dashboard');
                }
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    return (<Fragment></Fragment>)
}
export default connect(mapPropsToState)(Auth);