import Cookie from 'js-cookie'

const getCookie = (key) => Cookie.get(key);

const setCookie = (key, value, expires) => Cookie.set(key, value, expires);

const removeCookie = (key) => Cookie.remove(key);

export {
    getCookie,
    setCookie,
    removeCookie
}
