import React, { useEffect, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { AuthProvider } from "oidc-react";
import querystring from 'query-string';
import { getWeToken } from '@/service/user';
import { setCookie } from '@/utils/echo';
import { PROJECT } from '@/store/action-types';
import { connect } from 'react-redux';
// import { userInfo } from '@/service/user';
// import jwt_decode from 'jwt-decode';

const mapPropsToState = (state) => {
    return state.setting
}
const WeAuth = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
	useEffect(() => {
        async function init() {
            const { code, state } = querystring.parse(location.search);
            if (code) {
                if (code) {
                    const data = await getWeToken({code})
                    if (data.code === 200) {
                        const token = data.data.access_token;
                        setCookie(PROJECT, token, { expires: data.data.expires_in/86400 - 1 });
                        // const date = Math.floor(Date.now() / 1000)
                        // setCookie(REFRESHTOKEN, date + data.data.expires_in, { expires: 6 })
                        props.dispatch({
                            type: 'USERINFO',
                            payload: data.data
                        })
                        const arr = state.split('_');
                        navigate(`${arr[0]}${(arr[1] && (arr[1] !== 'false')) ? ('?client_id=' + arr[1]) : '' }`);
                    }
                }
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    return (<Fragment></Fragment>)
}
export default connect(mapPropsToState)(WeAuth);