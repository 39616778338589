import {combineReducers} from 'redux'
import setting from './settingReducer';
import user from './useInfo';

const appReducer = combineReducers({
    setting,
    user
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_DATA') {
    state.setting = undefined
    state.user.userInfo = undefined
  }
  return appReducer(state, action);
}

export default rootReducer;
